<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <b-card>
          <b-col>
            <h1 class="text-welcome-header">
              Dashboard
            </h1>
            <h3
              class="text-welcome-subheader"
            >
              {{ roles }}
            </h3>
          </b-col>
        </b-card>
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <data-statistics />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- <ecommerce-lastContents-table :table-data="data.companyTable"/>  -->
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError } from '@/store/functions'

import store from '@/store'
import { getUserData } from '@/auth/utils'

const DataStatistics = () => import('../DataStatistics.vue')
export default {
  components: {
    BRow,
    BCol,
    DataStatistics,
    BCard,
  },
  data() {
    return {
      roles: null,
      data: {},
    }
  },
  mounted() {
    this.roles = getUserData().groups.edges.map(group => this.$t(group.node.name)).join(', ')
  },
  created() {
    const userData = getUserData()
    const query = `{
      client(id: "${userData.profile.client.id}") {
        id
        name
        totalVideos
        totalProducts
        totalInteractivity
        contentInteractivityLimit
        initialCatLimit
        nestedCatLimit
        initialCatProductLimit
        nestedCatProductLimit
        productsCatLimit
      }
    }`
    axios.post('', { query }).then(response => {
      messageError(response, this)

      const data = response.data.data.client
      const platformConfig = {
        initialCategoryLimit: data.initialCatLimit,
        nestedCategoryLimit: data.nestedCatLimit,
        totalContentsLimit: data.totalVideos,
        totalProductsLimit: data.totalProducts,
        totalInteractivityLimit: data.totalInteractivity,
        contentInteractivityLimit: data.contentInteractivityLimit,
      }
      store.commit('app/SET_PLATFORM_CONFIGURATION', platformConfig)
    })
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
